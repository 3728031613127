import React from "react"
import SignUpModal from "../components/sign-up-modal";
import Layout from "../components/layout";
import SEO from "../components/seo";

const SignUp = () => (
	<Layout>
		<SEO title="Sign Up for Baushe"/>
		<div className="w-100 h-100 bg-light py-5">
			<SignUpModal
				dialog
				title={<>Create free <b className="text-primary">Baushe</b> account</>}
				description={<><span role="img" aria-label="wave">👋</span> You’re almost there. Please fill in the form to create a Baushe account.</>}
			/>
		</div>
	</Layout>
)

export default SignUp
