import React, {useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {netlifyFormHandle} from "../utils/form";

const SignUp = ({ title, description, dialog, onSuccess, redirect, group = '', ...rest }) => {
	const [loading, setLoading] = useState(false);
	const intl = useIntl();
	const handleSignUp = (e) => {
		setLoading(true);
		return netlifyFormHandle(e, redirect)
			.then(onSuccess)
			.then(() => setLoading(false))
	};
	const Component = dialog ? Modal.Dialog : Modal;
	const locale = intl.locale || (typeof window !== 'undefined' && window.navigator.language) || 'en';
	return (
		<Component {...rest}>
			<Form onSubmit={handleSignUp} action="/" method="POST" data-netlify="true" name="sign-up">
				<Modal.Header closeButton={!dialog} className="border-bottom-0 pb-0">
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="text-muted">{description}</p>
					<input type="hidden" value="sign-up" name="form-name"/>
					<input type="hidden" value={group} name="group"/>
					<input type="hidden" value={locale.slice(0, 2)} name="locale"/>
					<Form.Group controlId="formName">
						<Form.Label><FormattedMessage id="signup-form.name.label" defaultMessage="Your name" /></Form.Label>
						<Form.Control type="text" required name="name" placeholder={intl.formatMessage({ id: "signup-form.name.placeholder", defaultMessage: "Full name" })}/>
					</Form.Group>
					<Form.Group controlId="formEmail">
						<Form.Label><FormattedMessage id="signup-form.email.label" defaultMessage="Your email address" /></Form.Label>
						<Form.Control type="email" required name="email" placeholder="Email" />
						<Form.Text className="text-muted"><FormattedMessage id="signup-form.email.hint"  defaultMessage="You will receive further instructions on this email address" /></Form.Text>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button className="w-100" disabled={loading} variant="primary" type="submit">{loading ? (
						<>
							<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
							Loading...
						</>
					) : <FormattedMessage id="signup-form.button" defaultMessage="Sign Up"/>}</Button>
					<small className="text-muted">
						<FormattedMessage id="signup-form.disclaimer" defaultMessage="By providing your email address and clicking subscribe, you agree to the processing of your personal data for receiving the newsletter according to our {link}. You may unsubscribe at any time." values={{
							link: <a href="/privacy" target="_blank" rel="nofollow noopener"><FormattedMessage id="signup-form.privacy" defaultMessage="Privacy and Cookie Policy" /></a>
						}} />
					</small>
				</Modal.Footer>
			</Form>
		</Component>
	);
}

export default SignUp;